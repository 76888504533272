import React, {Component} from 'react'
import logo from './../../img/despacho_zwolf_logo_large.png'

export default class Header extends Component {

    style = {
        height: '3em',
        overflow: 'hidden'
    }

    render() {
        return (
            <nav id="mainNav"
                 className="navbar navbar-light bg-white navbar-expand-lg fixed-top text-uppercase p-3 Small shadow">
                <div className="container-fluid">
                    <a className="navbar-brand js-scroll-trigger" href="/home#pageTop">
                        <img fetchPriority="high" src={logo} alt="despacho" style={this.style}/>
                    </a>
                    <button className="navbar-toggler navbar-toggler-right text-uppercase rounded"
                            data-bs-target="#navbarResponsive" data-bs-toggle="collapse"
                            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa fa-bars"></i>
                    </button>
                    <div id="navbarResponsive" className="collapse navbar-collapse">
                        <ul className="navbar-nav fs-6 ms-auto ">
                            <li>
                                <a className="nav-link js-scroll-trigger px-3"
                                   href="https://sistema.grupozwolf.com/registro">Registrate</a>
                            </li>
                            <li>
                                <a className="nav-link js-scroll-trigger px-3"
                                   href="https://despachozwolf.com/Servicios">Servicios</a>
                            </li>
                            <li>
                                <a className="nav-link js-scroll-trigger px-3"
                                   href="https://despachozwolf.com/Blog">Blog</a>
                            </li>
                            <li>
                                <a className="nav-link js-scroll-trigger px-3"
                                   href="https://facturaonline.com.mx/">Facturar</a>
                            </li>
                            <li>
                                <a className="nav-link js-scroll-trigger px-3" href="/home#section1">Contacto</a>
                            </li>
                            <li>
                                <a className="nav-link js-scroll-trigger px-3" href="/preguntas-frecuentes">Preguntas
                                    Frecuentes</a>
                            </li>
                            <li>
                                <a className="nav-link js-scroll-trigger px-3" href="https://sistema.grupozwolf.com/">Iniciar
                                    Sesion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}
